<template>
    <div>
        <div class="flex items-center justify-between bg-white border rounded-t-sm px-4 shadow-lg">
            <div>
                <stepper>
                    <li><button :class="{'active': status == 'Draft'}">DRAFT</button></li>
                    <li><button :class="{'active': status == 'Submitted'}">SUMBITTED</button></li>
                    <li><button :class="{'active': status == 'Approved'}">APPROVED</button></li>
                    <li><button :class="{'active': status == 'Paid'}">PAID</button></li>
                    <li><button :class="{'active': status == 'Reused'}">REFUSED</button></li>
                </stepper>
            </div>
            <div>
                <!-- <button class="mr-1.5 h-7 border border-transparent px-2 text-teal-500 hover:text-gray-500 hover:border-gray-200 hover:bg-gray-100">RESET TO DRAFT</button>
                <button class="mr-1.5 h-7 border border-transparent px-2 text-teal-500 hover:text-gray-500 hover:border-gray-200 hover:bg-gray-100">REFUSE</button> -->
                <button v-if="status == 'Draft'" @click="onConfirm" class="mr-1.5 bg-teal-500 h-7 border border-transparent px-2 text-teal-50 hover:bg-teal-600 relative" :disabled="isDisabled">
                    <div v-if="isDisabled" class="absolute bg-teal-400 bg-opacity-70 w-full h-full inset-0 rounded-sm flex items-center justify-center">
                        <svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    </div>
                    SUBMIT TO MANAGER
                </button>
                <button v-if="status == 'Submitted'" @click="onApprove" class="mr-1.5 bg-teal-500 h-7 border border-transparent px-2 text-teal-50 hover:bg-teal-600 relative" :disabled="isDisabled">
                    <div v-if="isDisabled" class="absolute bg-teal-400 bg-opacity-70 w-full h-full inset-0 rounded-sm flex items-center justify-center">
                        <svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    </div>
                    APPROVE
                </button>
                <button v-if="status == 'Approved'" @click="onRegisterPayment" class="mr-1.5 bg-teal-500 h-7 border border-transparent px-2 text-teal-50 hover:bg-teal-600 relative" :disabled="isDisabled">
                    <div v-if="isDisabled" class="absolute bg-teal-400 bg-opacity-70 w-full h-full inset-0 rounded-sm flex items-center justify-center">
                        <svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    </div>
                    REGISTER PAYMENT
                </button>
            </div>
        </div>
        <div class="m-5">
            <div class="bg-white border rounded-t-sm">
                <div class="flex justify-between items-center">
                    <div class="flex whitespace-nowrap cursor-pointer relative font-medium">
                        <div class="border-b-2 px-5 inline-block text-center pt-3 pb-2 border-red-500 hover:border-gray-200 hover:text-red-600">INFO</div>
                    </div>
                </div>
            </div>
            <div class="border border-t-0 rounded-b-sm p-5 bg-white">
                <form @submit.prevent="onSubmit">
                    <div class="mb-5">
                        <label for="">Description</label>
                        <input v-model="form.description" @keydown="error.description=''" :class="{'border-red-500': error.description}" type="text" class="border-b text-2xl focus:outline-none w-full focus:border-teal-500" placeholder="e.g. Lunch with Customer">
                    </div>
                    <div class="grid grid-cols-2 gap-16 mb-5">
                        <div>
                            <div class="grid grid-cols-10 gap-2 mb-5">
                                <div class="col-span-2 items-center flex">Category</div>
                                <div class="col-span-8 slt" :class="{'err': error.categoryId}">
                                    <select-2 ref="categoryId" v-model="form.categoryId" @input="error.categoryId=''" :options="categoryList" class="form-control" :placeholder="'Select category'" @onAdd="onShowModalCategory" :button="true" :buttonText="'Add Category'">
                                        <option value=""></option>
                                    </select-2>
                                </div>
                            </div>
                            <div class="grid grid-cols-10 gap-2 mb-5">
                                <div class="col-span-2 flex items-center">Total</div>
                                <div class="col-span-8">
                                    <autonumeric
                                        class="border-b focus:outline-none w-full px-3"
                                        :class="{'border-red-500': error.total}"
                                        :options="{modifyValueOnWheel: false}"
                                        @keydown.native="error.total=''"
                                        v-model="form.total"
                                    >
                                    </autonumeric>
                                </div>
                            </div>
                        <div class="grid grid-cols-10 gap-2 mb-5">
                                <div class="col-span-2 items-center flex">Employee</div>
                                <div class="col-span-8 slt">
                                    <select-2 ref="employeeId" v-model="form.employeeId" :options="userList" class="form-control" :placeholder="'Select Employee'">
                                        <option value=""></option>
                                    </select-2>
                                </div>
                            </div>
                            <div class="grid grid-cols-10 gap-2">
                                <div class="col-span-2 flex items-center">Paid By</div>
                                <div class="col-span-8 flex space-x-10">
                                    <div class="form-radio">
                                        <input v-model="form.paidBy" :value="'Company'" type="radio" id="radioinline1" name="radioinline">
                                        <label for="radioinline1">Company</label>
                                    </div>
                                    <div class="form-radio">
                                        <input v-model="form.paidBy" :value="'Employee'" type="radio" id="radioinline2" name="radioinline">
                                        <label for="radioinline2">Employee (to reimburse)</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="grid grid-cols-10 gap-2 mb-5">
                                <div class="col-span-2">Bill Reference</div>
                                <div class="col-span-8">
                                    <input v-model="form.billReference" type="text" class="border-b w-full focus: outline-none">
                                </div>
                            </div>
                            <div class="grid grid-cols-10 gap-2 mb-5">
                                <div class="col-span-2 flex items-center">Expense Date</div>
                                <div class="col-span-8">
                                    <date-picker v-model="form.expenseDate" :masks="masks" class="mt-2">
                                        <template v-slot="{inputValue, togglePopover}">
                                            <div class="flex">
                                                <input :value="inputValue" @click="togglePopover" type="text" class="border-b focus:outline-none w-full">
                                            </div>
                                        </template>
                                    </date-picker>
                                </div>
                            </div>
                            <div>
                                <textarea v-model="form.note" rows="3" class="w-full resize-none focus:outline-none" placeholder="Note..."></textarea>
                            </div>
                        </div>
                    </div>
                    <legend class="flex items-center justify-between mb-3 py-2 border-b">
                        <div class="font-medium">ATTACH RECIPT</div>
                        <div class="relative border rounded-sm">
                            <div class="flex items-center px-3 py-1">
                                <div><i class="icon-file-upload mr-2"></i></div>
                                <div>Add more file</div>
                            </div>
                            <div class="absolute w-full h-full bg-black top-0 opacity-0 rounded-sm hover:opacity-5">
                                <input ref="fileattachment" @input="onChangeFileAttachment" class="opacity-0 absolute right-0 w-32 h-full" type="file" multiple accept=".jpg, .jpeg, .png">
                            </div>
                        </div>
                    </legend>
                    <ul>
                        <li v-for="(file, i) in attachments" :key="i" class="border-b border-dashed">
                            <div class="flex items-center justify-between">
                                <img :src="file" alt="" width="200">
                                <a @click.prevent="removeAttachement(i)" href="#" class="text-red-500">
                                    <i class="icon-cancel-circle2"></i>
                                </a>
                            </div> 
                        </li>
                    </ul>
                </form>
            </div>
        </div>
        <!-- Modal Category -->
        <modal :show="modalCategory">
            <div class=" w-1/3 bg-white border rounded-sm p-5">
                <div class="font-semibold mb-5">ADD EXPENSE CATEGORY</div>
                <form @submit.prevent="onSubmitCategory">
                    <div class="mb-5">
                        <label for="">Category Name</label>
                        <input v-model="formCategory.name" type="text" @keydown="errCategory.name = ''" :class="{'border-red-500': errCategory.name}" class="form-control mt-2" placeholder="e.g. General Expense">
                    </div>
                    <div class="text-right">
                        <button @click="onCloseModalCategory" type="button" class="bg-red-500 rounded-sm hover:bg-red-600 h-9 px-5 text-red-50 mr-2">Cancel</button>
                        <button type="submit" :disabled="isDisabled" class="bg-teal-500 rounded-sm hover:bg-teal-600 h-9 px-5 text-teal-50">Save</button>
                    </div>
                </form>
            </div>
        </modal>

        <!-- REGISTER PAYMENT -->
        <modal :show="modalPayment">
            <form @submit.prevent="onSubmitPayment" class="w-2/3 bg-white border rounded-sm">
                <div class="flex items-center justify-between px-5 py-3 border-b">
                    <div class="font-medium">REGISTER PAYMENT</div>
                </div>
                <div class="p-5 mb-5">
                    <div class="grid grid-cols-2 gap-16">
                        <div class="col-span-1">
                            <div class="grid grid-cols-7">
                                <div class="col-span-2">
                                    <label for="">Journal</label>
                                </div>
                                <div class="col-span-5 mb-2">
                                    <dropdown class="w-full" v-model="formPayment.journal" :items="journals">
                                        <template #default="data">
                                            <div class="cursor-pointer border-b  bg-white flex items-center">
                                                <button class="w-full flex h-full items-center focus:outline-none">
                                                    <span  class="mr-auto flex items-center">{{formPayment.journal}}</span>
                                                    <i :class="{'-rotate-180': data.show}" class="transform duration-200 icon-arrow-down22"></i>
                                                </button>
                                            </div>
                                        </template>
                                        <template #items="items">
                                            <div>{{items.item.name}}</div>
                                        </template>
                                    </dropdown>
                                </div>
                            </div>
                            <div v-show="formPayment.journal == 'Bank'" class="grid grid-cols-7">
                                <div class="col-span-2">
                                    <label for="">Account Bank</label>
                                </div>
                                <div class="col-span-5">
                                    <dropdown class="w-full" v-model="formPayment.bankName" :items="banks">
                                        <template #default="data">
                                            <div class="cursor-pointer border-b  bg-white flex items-center" :class="{'border-red-500': errPayment.bankId}">
                                                <button class="w-full flex h-full items-center justify-between focus:outline-none">
                                                    <span v-if="formPayment.bankName">{{formPayment.bankName}}</span>
                                                    <span v-else class="text-gray-400">Select account bank</span>
                                                    <i :class="{'-rotate-180': data.show}" class="transform duration-200 icon-arrow-down22"></i>
                                                </button>
                                            </div>
                                        </template>
                                        <template #items="items">
                                            <div>{{items.item.name}}</div>
                                            <div class="text-gray-400">{{items.item.accountName}}</div>
                                        </template>
                                    </dropdown>
                                </div>
                            </div>
                        </div>

                        <div class="col-span-1">
                            <div class="grid grid-cols-7">
                                <div class="col-span-2">
                                    <label for="">Amount</label>
                                </div>
                                <div class="col-span-5">
                                    <autonumeric
                                        :class="{'border-red-500': errPayment.amount}"
                                        v-model="formPayment.amount"
                                        @keydown.native="errPayment.amount = ''"
                                        class="w-full border-b focus:outline-none group-hover:bg-gray-100 mb-2"
                                        :options="['integer', {modifyValueOnWheel : false}]" 
                                    >
                                    </autonumeric>
                                </div>
                            </div>
                            <div class="grid grid-cols-7">
                                <div class="col-span-2">
                                    <label for="">Payment Date</label>
                                </div>
                                <div class="col-span-5">
                                    <date-picker v-model="formPayment.paymentDate" :masks="masks">
                                        <template v-slot="{inputValue, togglePopover}">
                                            <input :value="inputValue" type="text" @click="togglePopover()" class="border-b focus:outline-none w-full" readonly>
                                        </template>
                                    </date-picker>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="py-2 text-right px-5 border-t">
                    <button @click="modalPayment = false" type="button" class="h-9 px-5 rounded-sm hover:bg-gray-100 hover:border-gray-200 mr-2">CANCEL</button>
                    <button type="submit" class="h-9 px-5 bg-teal-500 rounded-sm text-teal-50" :disabled="isDisabled">CREATE PAYMNET</button>
                </div>
            </form>
        </modal>
    </div>
</template>

<script>
import stepper from '../../components/stepper.vue'
import Select2 from '../../components/Select2.vue'
import Autonumeric from '../../components/Autonumeric.vue'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import Modal from '../../components/Modal.vue'
import axios from 'axios'
import {mapGetters} from 'vuex'
import BreadcrumbManager from '../../mixins/breadcrumbsManager.vue'
import {EventBus} from '../../event-bus'
import Dropdown from '../../components/Dropdown.vue'
export default {
    components: {stepper, Select2, Autonumeric, DatePicker, Modal, Dropdown},
    mixins: [BreadcrumbManager],
    data () {
        return {
            isDisabled: false,
            imgURL: process.env.VUE_APP_ROOT_API,
            masks: {
                input: 'DD/MM/YYYY'
            }, 
            categories: [],
            users: [],
            expenseId: '',
            status: '',
            form: {
                description: '',
                categoryId: '',
                total: '',
                employeeId: '',
                paidBy: 'Company',
                billReference: '', 
                expenseDate: new Date(),
                note: '',
                type: ''
            },
            error : {
                description: '',
                categoryId: '',
                total: '',
                employeeId: '',
                paidBy: '',
                billReference: '', 
                expenseDate: new Date(),
                note: ''
            },
            attachments: [],
            formCategory: {
                name: ''
            },
            modalCategory: false,
            errCategory: {
                name: ''
            },
            modalPayment: false,
            journals: [{name: 'Bank'},{name: 'Debit'}, {name: 'Cash'}],
            banks: [],
            formPayment: {
                journal: 'Bank',
                paymentDate: new Date(),
                amount: '',
                amountDue: '',
                bankId: '',
                bankName: '',
            },
            errPayment: {
                amount: '',
                bankId: '',
            },
        }
    },
    mounted () {
        let expenseId = this.$route.params.id
        this.getData(expenseId)
        this.setButtonText('SAVE')
        this.setDiscardText('DISCARD')
        this.setDiscard(true)
        this.setAction(false)
        EventBus.$on('onClick', this.onSave)
        EventBus.$on('onCancel', this.onCancel)
    },
    destroyed () {
        EventBus.$off('onClick', this.onSave)
        EventBus.$off('onCancel', this.onCancel)
    },
    computed: {
        ...mapGetters({
            user: 'auth/user'
        }),
        categoryList () {
            return this.categories.map(obj => {
                obj.id = obj._id
                obj.text = obj.name
                return obj
            })
        },
        userList () {
            return this.users.map(obj => {
                obj.id = obj._id
                obj.text = obj.name
                return obj
            })
        },
    },
    methods: {
        getData (id) {
            const getBase64FromUrl = async (url) => {
                const data = await fetch(url);
                const blob = await data.blob();
                return new Promise((resolve) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(blob); 
                    reader.onloadend = function() {
                        const base64data = reader.result;   
                        resolve(base64data);
                    }
                });
            }
            axios.get('/expenses/detail/'+id)
            .then(res => {
                this.attachments = []
                this.categories = res.data.categories
                this.users = res.data.users
                let expense = res.data.expense
                this.banks = res.data.banks
                this.status = expense.status
                this.$nextTick( async () => {
                    this.expenseId = expense._id
                    this.form.description = expense.description
                    this.form.categoryId = expense.categoryId
                    this.form.total = expense.total
                    this.form.employeeId = expense.employeeId
                    this.form.billReference = expense.billReference
                    this.form.expenseDate = expense.expenseDate
                    this.form.note = expense.note
                    for (let img of expense.attachments) {
                        await getBase64FromUrl(this.imgURL+'/public/img/expenses/'+img)
                        .then(res => {
                            this.attachments.push(res)
                        })
                    }
                    this.pushBreadcrumb({text: expense.description})
                    this.$refs.categoryId.update()
                    this.$refs.employeeId.update()
                })
            })
        },
        onConfirm () {
            this.form.type = 'submit'
            this.onSubmit()
        },
        onApprove () {
            this.form.type = 'approve'
            this.onSubmit()
        }, 
        onRegisterPayment () {
            this.form.type = 'payment'
            this.modalPayment = true
        }, 
        onSave () {
            this.form.type = 'update'
            this.onSubmit()
        },
        async onSubmit () {
            function dataURItoBlob(dataURI) {
                var byteString = atob(dataURI.split(',')[1]);
                var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
                var ab = new ArrayBuffer(byteString.length);
                var ia = new Uint8Array(ab);
                for (var i = 0; i < byteString.length; i++) {
                    ia[i] = byteString.charCodeAt(i);
                }
                var blob = new Blob([ab], {type: mimeString});
                return blob;
            }
            if(!this.form.description) {
                this.error.description = true
            }
            if(!this.form.categoryId) {
                this.error.categoryId = true
            }
            if(!this.form.total) {
                this.error.total = true
            }
            if(this.form.description && this.form.categoryId && this.form.total) {
                this.isDisabled = false
                const formData = new FormData()
                const files = []
                for (let image of this.attachments) {
                    const blob = dataURItoBlob(image)
                    files.push(blob)
                }
                for (let i = 0; i < files.length; i++) {
                    formData.append('attachments', files[i])
                }
                formData.append('description', this.form.description)
                formData.append('categoryId', this.form.categoryId)
                formData.append('total', this.form.total)
                formData.append('employeeId', this.form.employeeId)
                formData.append('paidBy', this.form.paidBy)
                formData.append('billReference', this.form.billReference)
                formData.append('expenseDate', this.form.expenseDate)
                formData.append('note', this.form.note)
                formData.append('type', this.form.type)

                axios.put('/expenses/update/'+this.expenseId, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((res) => {
                    this.isDisabled = false
                    this.getData(res.data._id)
                })
            }

        },
        onSubmitPayment () {
            if(!this.formPayment.amount) {
                this.errPayment.amount = true
            }
            if(this.formPayment.journal == 'Bank' && !this.formPayment.bankId) {
                this.errPayment.bankId = true
            }
            if(this.formPayment.journal == 'Debit' && !this.formPayment.bankId) {
                this.errPayment.bankId = true
            }
            if(this.formPayment.amount && this.formPayment.journal == 'Cash' || this.formPayment.amount && this.formPayment.journal == 'Bank' && this.formPayment.bankId || this.formPayment.amount && this.formPayment.journal == 'Debit' && this.formPayment.bankId) {
                this.isDisabled = true
                axios.post('/expenses/payment/'+this.expenseId, this.formPayment)
                .then((res) => {
                    this.modalPayment = false
                    this.getData(res.data._id)
                    this.isDisabled = false
                })
            }
        },
        async onChangeFileAttachment (e) {
            let files = []
            let app = this
            for await (let file of e.target.files) {
                files.push(file)
            }
            const filter = files.filter(x => x.name != this.attachments.includes(x.name))
            filter.forEach(el => {
                const reader = new FileReader()
                reader.onload = () => {
                    app.attachments.push(reader.result)
                }
                reader.readAsDataURL(el)
            })
            
        },
        removeAttachement (index) {
            this.attachments.splice(index, 1)
            this.$refs.fileattachment.value = ''
        },
        onShowModalCategory () {
            this.modalCategory = true
            this.formCategory.name = ''
            this.errCategory.name = ''
        },
        onCloseModalCategory () {
            this.modalCategory = false
            this.formCategory.name = ''
            this.errCategory.name = ''
        },
        onSubmitCategory () {
            if(!this.formCategory.name) {
                this.errCategory.name = true
            }
            if(this.formCategory.name) {
                this.isDisabled = true
                axios.post('/expenses/category', this.formCategory)
                .then(res => {
                    this.isDisabled = false
                    this.categories.push(res.data)
                    this.$nextTick(() => {
                        this.form.categoryId = res.data._id
                    })
                    this.onCloseModalCategory()
                })
            }
        },
        onCancel () {
            this.$router.push('/expenses')
        }
    },
    watch: {
        modalPayment (val) {
            if(val) {
                this.formPayment.amount = this.form.total
            }
        },
        status (val) {
            if(val == 'Approved') {
                this.setButtonText()
            }
            if(val == 'Paid') {
                this.setButtonText()
            }
        },
        'formPayment.journal': function () {
            this.formPayment.bankName = ''
            this.formPayment.bankId = ''
            this.errPayment.bankId = ''
        },
        'formPayment.bankName': function(val) {
            if(val) {
                let bank = this.banks.find(obj => obj.name == val)
                this.formPayment.bankId = bank._id
                this.errPayment.bankId = ''
            }
        },
    }
}
</script>

<style scoped>
    input[type=file]::-webkit-file-upload-button { /* chromes and blink button */
        cursor: pointer; 
        display: none;
    }
    input[type=file] {
        cursor: pointer;
    }
    .float > div { float: left; }
    textarea {resize: none}

</style>